<template>
  <div>
    <cgn-lazy-image :image-id="props.templatevar.image" :image-aspect="props.templatevar.aspect" image-width="300" class="mt-2 w-full" />
    <div class="text-2xl">
      {{ props.templatevar.heading }}
    </div>
    <div class="prose mt-2 max-w-full dark:prose-dark" v-html="props.templatevar.content" />
  </div>
</template>

<script lang="ts">
class Templatevars {
  heading?: string
  content?: string
  image?: string
  aspect?: string
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
})
</script>
