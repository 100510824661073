import { CognitoAddressLookup } from '~cognito/models/Cognito/AddressLookup'
import { CognitoBase } from '~cognito/models/Cognito/Base'
import { CognitoImage } from '~cognito/models/Cognito/Image'
import { $axios } from '~cognito/plugins/axios'

class TplUser extends CognitoBase {
  first_name: string
  last_name: string
  image: CognitoImage
  company_name: string
  trading_name: string
  mobile_phone: string
  company_phone: string
  email: string
  address: string
  website: string
  facebook_link: string
  new_address: CognitoAddressLookup

  baseurl() {
    return '/api/v1/tpl/user'
  }

  constructor(source?: Partial<TplUser>) {
    super()
    this.first_name = ''
    this.last_name = ''
    this.image = new CognitoImage()
    this.company_name = ''
    this.trading_name = ''
    this.mobile_phone = ''
    this.company_phone = ''
    this.email = ''
    this.address = ''
    this.website = ''
    this.facebook_link = ''
    this.new_address = new CognitoAddressLookup()

    Object.assign(this, source)
  }

  async getProfile(): Promise<TplUser> {
    const res = await $axios.get(`${this.baseurl()}/getProfile`)
    return res.data
  }

  async saveProfile(): Promise<{
    success: boolean
    message: string
  }> {
    const res = await $axios.post(`${this.baseurl()}/saveProfile`, {
      data: this,
    })
      .catch(() => {
        console.log('catch')
        return {
          success: false,
          message: 'Failure',
        }
      })
    return res.data
  }
}

export { TplUser }
