<template>
  <div v-if="widget.outer == 'Tpl/links'" :class="containedClass">
    <page-builder-tpl-links :templatevar="widget.templatevar" />
  </div>
  <div v-else-if="widget.outer == 'Tpl/heading'">
    <page-builder-heading :templatevar="widget.templatevar" />
  </div>
  <div v-else-if="widget.outer == 'Tpl/banner_and_content'" :class="containedClass">
    <page-builder-banner-and-content :templatevar="widget.templatevar" />
  </div>
  <div v-else-if="widget.outer == 'Event/upcoming_list'" :class="containedClass">
    <page-builder-event-upcoming-list :templatevar="widget.templatevar" />
  </div>
  <div v-else-if="widget.outer == 'Event/buySpectator'" :class="containedClass">
    <page-builder-event-buy-spectator :templatevar="widget.templatevar" :item="props.urlParts.item_url" />
  </div>
  <div v-else-if="widget.outer == 'Event/enter'" :class="containedClass">
    <page-builder-event-enter :templatevar="widget.templatevar" :item="props.urlParts.item_url" />
  </div>
  <div v-else-if="widget.outer == 'Event/applyStall'" :class="containedClass">
    <page-builder-event-apply-stall :templatevar="widget.templatevar" :item="props.urlParts.item_url" />
  </div>
  <div v-else-if="widget.outer == 'Market/upcoming_list'" :class="containedClass">
    <page-builder-market-upcoming-list :templatevar="widget.templatevar" />
  </div>
  <div v-else-if="widget.outer == 'Market/apply_stallholder'" :class="containedClass">
    <page-builder-market-apply-stallholder :templatevar="widget.templatevar" :item="props.urlParts.item_url" />
  </div>
  <div v-else-if="widget.outer == 'Caravan/book'" :class="containedClass">
    <page-builder-caravan-book :templatevar="widget.templatevar" />
  </div>
  <cgn-page-builder v-else :widget="widget" :url-parts="urlParts" :contained-class="props.containedClass" />
</template>

<script setup lang="ts">
import { CognitoUrlParts } from '~cognito/models/Cognito/Page'

const props = defineProps({
  widget: {
    required: true,
  },
  containedClass: {
    type: String,
    default: 'px-6 py-2 mx-auto max-w-5xl',
  },
  urlParts: {
    type: CognitoUrlParts,
    required: true,
  },
})
</script>
