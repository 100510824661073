import { config } from '../cognitocmsapi/default_config.js'

config.devURL = `https://iss.${config.devbase}`
config.prodURL = 'https://cms.ipswichshow.com.au'
config.siteURL = 'https://ipswichshow.com.au'
config.baseURL = config.devbase == '' ? config.prodURL : config.devURL

config.isEcommerce = false

config.profile.edit_company_name = true
config.profile.edit_company_phone = true
config.profile.edit_trading_name = true
config.profile.edit_abn = true
config.profile.edit_website = true
config.profile.edit_facebook = true
config.profile.manage_addresses = true
config.payment_to_user_id = 12
config.sentryDsn = 'https://fe87e838f2b34baaa7b4e572c8a82d45@glitchtip.logger.jm1.au/5'
config.pagebuilderImages.push({
  widget: 'Tpl/heading',
  images: [
    {
      name: 'background',
      image_aspect: '16x5',
      image_width: 1920,
    },
  ],
})
export { config }
