<template>
  <div v-if="has_payment" class="mx-auto my-2 w-full max-w-2xl">
    <cgn-alert-success v-if="payment_ok">
      Payment Successful
      Please check your email for your receipt
    </cgn-alert-success>
    <cgn-alert-danger v-else>
      Payment Failed
    </cgn-alert-danger>
  </div>
  <cgn-spinner v-else-if="loading" />
  <div v-else-if="eventDetails.id">
    <form @submit.prevent="submitEntry">
      <cgn-lazy-image v-if="eventDetails.image" :image="eventDetails.image" class="mb-2 w-full" />
      <h1 class="mb-2 text-2xl">
        {{ eventDetails.name }}
      </h1>
      <div class="mb-2 font-bold">
        {{ eventDetails.start_date.betweenDateString(eventDetails.end_date) }}
      </div>
      <div class="mb-2">
        <div v-for="date in eventDetails.dates" :key="date.start_time">
          <span v-if="!eventDetails.start_date.isSameDay(eventDetails.end_date)">
            {{ date.start.format('eee do MMMM') }}
            &mdash;
          </span>
          {{ date.start.toHumanTimeString() }}
          -
          {{ date.end.toHumanTimeString() }}
        </div>
      </div>
      <div class="prose max-w-none dark:prose-invert" v-html="eventDetails.blurb" />
      <div class="prose max-w-none dark:prose-invert" v-html="eventDetails.spectator_content" />

      <div v-if="entryForm.spectators.length > 0">
        <div class="mb-2 mt-10 text-xl">
          Buy Spectator Tickets
        </div>
        <div class="grid-cols-2 gap-2 md:grid">
          <cgn-form-input v-model="entryForm.first_name" label="First Name" required />
          <cgn-form-input v-model="entryForm.last_name" label="Last Name" required />
          <cgn-form-input-email v-model="entryForm.email" label="Email" required />
          <cgn-form-input-phone v-model="entryForm.mobile_phone" label="Mobile Phone" />
        </div>
        <div class="flex w-full flex-col gap-2 pb-2">
          <div v-for="spectator in entryForm.spectators" :key="spectator.id" class="flex flex-col justify-between rounded-md bg-white p-2 dark:bg-darkbg-500 md:flex-row">
            <div>
              <div>
                ${{ spectator.price.toFixed(2) }}
              </div>
              <div class="text-lg">
                {{ spectator.label }}
              </div>
            </div>
            <div v-if="spectator.disabled">
              Sold out!
            </div>
            <div v-else>
              <cgn-form-input v-model="spectator.qty" type="number" min-amount="0" :max-amount="spectator.qty_available > 0 ? spectator.qty_available : 100" />
            </div>
          </div>
        </div>
      </div>

      <cgn-alert-danger v-if="errorMessage" class="font-bold">
        {{ errorMessage }}
      </cgn-alert-danger>
      <cgn-button v-if="paymentAmount > 0" color-brand fullwidth class="font-bold">
        Pay ${{ paymentAmount.toFixed(2) }} Now
      </cgn-button>
    </form>
  </div>
</template>

<script lang="ts">
import { CognitoPayment } from '~cognito/models/Cognito/Payment'
import { EventEntryForm } from '~cognito/models/Event/EntryForm'
import { EventEvent } from '~cognito/models/Event/Event'

class Templatevars {
  item?: string
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
  item: {
    type: String,
    required: true,
  },
})

const loading = ref(false)
const eventDetails = ref<EventEvent>(new EventEvent())
const entryForm = ref<EventEntryForm>(new EventEntryForm())

const errorMessage = ref('')
const has_payment = ref(false)
const payment_ok = ref(false)
const submitEntry = async () => {
  errorMessage.value = ''
  const result = await new EventEvent().submitEntry(entryForm.value)
  errorMessage.value = result.message
  if (result.success) {
    window.location = result.redirect
  }
}
const paymentAmount = computed(() => {
  return entryForm.value.totalPrice()
})

onMounted(async () => {
  loading.value = true
  new CognitoPayment().checkSuccessful()
    .then((data) => {
      has_payment.value = data.has_payment
      payment_ok.value = data.success
    })
  eventDetails.value = await entryForm.value.loadEvent(props.item)

  loading.value = false
})
</script>
