import { CognitoBase } from '~cognito/models/Cognito/Base'
import { CognitoPage } from '~cognito/models/Cognito/Page'

class MarketType extends CognitoBase {
  name: string
  page: CognitoPage

  baseurl() {
    return '/api/v1/market/type'
  }

  constructor(source?: Partial<MarketType>) {
    super()
    this.name = ''
    this.page = new CognitoPage()
    Object.assign(this, source)
  }
}

export { MarketType }
