import { CognitoBase } from '~cognito/models/Cognito/Base'

class MarketSiteType extends CognitoBase {
  name: string
  qty_available: number
  amount: number

  baseurl() {
    return '/api/v1/market/siteType'
  }

  constructor(source?: Partial<MarketSiteType>) {
    super()
    this.name = ''
    this.qty_available = 0
    this.amount = 0
    Object.assign(this, source)
  }
}

export { MarketSiteType }
