<template>
  <div v-if="has_payment" class="mx-auto my-2 w-full max-w-2xl">
    <cgn-alert-success v-if="payment_ok">
      Your application has been received.
      Please check your email for confirmation.
    </cgn-alert-success>
    <cgn-alert-danger v-else>
      Payment Failed
    </cgn-alert-danger>
  </div>
  <cgn-spinner v-else-if="loading" />
  <div v-else-if="eventDetails.id">
    <form @submit.prevent="submitEntry">
      <cgn-lazy-image v-if="eventDetails.image" :image="eventDetails.image" class="mb-2 w-full" />
      <h1 class="mb-2 text-2xl">
        {{ eventDetails.name }}
      </h1>
      <div class="mb-2 font-bold">
        {{ eventDetails.start_date.betweenDateString(eventDetails.end_date) }}
      </div>
      <div class="mb-2">
        <div v-for="date in eventDetails.dates" :key="date.start_time">
          <span v-if="!eventDetails.start_date.isSameDay(eventDetails.end_date)">
            {{ date.start.format('eee do MMMM') }}
            &mdash;
          </span>
          {{ date.start.toHumanTimeString() }}
          -
          {{ date.end.toHumanTimeString() }}
        </div>
      </div>
      <div class="prose max-w-none dark:prose-invert" v-html="eventDetails.blurb" />

      <div v-if="eventDetails.user_cannot_apply_stall_site_reason">
        <cgn-alert-danger>
          Your profile is incomplete.
          Please update it
        </cgn-alert-danger>
        <form @submit.prevent="updateProfile">
          <div class="grid gap-2 sm:grid-cols-2">
            <cgn-form-input-email v-model="profileFormValues.email" label="Email" required />
            <cgn-form-input-phone
              v-model="profileFormValues.mobile_phone"
              label="Phone"
              required
            />

            <cgn-form-input-text
              v-model="profileFormValues.company_name"
              label="Company Name"
            />

            <cgn-form-input-text
              v-model="profileFormValues.abn"
              label="ABN"
            />

            <cgn-form-input-text
              v-model="profileFormValues.trading_name"
              label="Trading Name"
              required
            />

            <cgn-form-input-phone
              v-model="profileFormValues.company_phone"
              label="Company Phone"
              required
            />

            <cgn-form-input-text
              v-model="profileFormValues.website"
              label="Website"
            />

            <cgn-form-input-text
              v-model="profileFormValues.facebook_link"
              label="Facebook"
            />
          </div>
          <cgn-button fullwidth color-brand>
            Update Profile
          </cgn-button>
        </form>
      </div>
      <div v-else-if="eventDetails.stall_site_types.length > 0">
        <div class="mb-2 mt-10 text-xl">
          Apply for a stall
        </div>
        <cgn-form-radio-button v-model="entryForm.stall_site_type_id" :options="entryForm.stallSiteTypeRadio" label="Site Type" required />
        <template v-if="entryForm.stall_site_type_id">
          <cgn-form-radio-button v-model="entryForm.stall_site_location_id" :options="entryForm.stallSiteLocationRadio" label="Location" />
          <cgn-form-input-text v-if="entryForm.stall_site_location_id" v-model="entryForm.stall_site_preferred_location" label="Preferred location in the above area" />
          <cgn-form-input-text v-model="entryForm.stall_products_and_display_description" label="Description of the products you sell" />
          <cgn-form-input-text v-model="entryForm.stall_company_product_overview" label="Overview of your company" />
          <cgn-form-input-text v-model="entryForm.stall_width" label="Stall Width (m)" />
          <cgn-form-input-text v-model="entryForm.stall_length" label="Stall Length (m)" />
          <cgn-form-input-text v-model="entryForm.stall_height" label="Stall Height (m)" />
          <cgn-form-input-text v-model="entryForm.stall_vehicle_make_and_model" label="Bump in/out Vehicle make and model" />
          <cgn-form-input-text v-model="entryForm.stall_vehicle_registration" label="Bump in/out Vehicle registration" />
          <div class="my-4">
            <div class="text-xl">
              Power Requirements
            </div>
            <div class="text-sm text-red-500">
              Please enter details of each power point required separately
            </div>
            <div v-if="entryForm.stall_power.length == 0">
              <cgn-form-checkbox label="I have no power requirements" required />
            </div>
            <div v-for="power, idx in entryForm.stall_power" :key="idx">
              <i-heroicons-solid:trash class="inline cursor-pointer" @click="entryForm.stall_power.splice(idx, 1)" />
              1 x
              {{ eventDetails.stall_power_types.find(e => e.id == power.id)?.name }}:
              {{ power.reason }}
            </div>
            <form @submit.prevent="addPower">
              <div class="grid gap-2" :class="entryFormPower ? 'md:grid-cols-2' : ''">
                <cgn-form-dropdown v-model="entryFormPower" :options="eventDetails.stall_power_types" label="Add power requirement" required />
                <cgn-form-input-text
                  v-if="entryFormPower" v-model="entryFormPowerReason" label="Reason for use" required
                  placeholder="What is the use for this power point?"
                />
              </div>
              <cgn-button v-if="entryFormPower" color-brand class="w-full">
                Add power requirement
              </cgn-button>
            </form>
          </div>
          <div class="my-4">
            <div class="text-xl">
              Please add current photos of your display / stand / business
            </div>
            <cgn-form-photos v-model="entryForm.stall_photos" file-button-label="Upload Photos" />
          </div>
          <div class="my-6">
            <div class="text-xl">
              Public Liability Insurance
            </div>

            <div v-if="eventDetails.stall_public_liability_insurance">
              <cgn-alert-success v-if="eventDetails.stall_public_liability_insurance.expiry.isAfter(eventDetails.start_date)">
                We already have your Public Liability Insurance document
              </cgn-alert-success>
              <div v-else>
                <cgn-alert-warning>
                  We have your Public Liability Insurance document but it will expire before the event.
                </cgn-alert-warning>
                <cgn-form-tristate v-model="havePLI" label="I have a new Public Liability Insurance policy" />
                <div v-if="havePLI">
                  <cgn-form-input-file v-model="entryForm.stall_public_liability_insurance_file" label="Upload new document now:" />
                  <cgn-form-input v-model="entryForm.stall_public_liability_insurance_expiry" type="date" label="Expiry date of Insurance" required />
                </div>
                <div v-if="havePLI === 0">
                  <cgn-form-checkbox v-model="entryForm.stall_public_liability_insurance_will_update_on_renewal" label="I will upload the Public Liability Insurance once it is renewed, but before the event" required />
                </div>
              </div>
            </div>
            <div v-else>
              <cgn-form-tristate v-if="selectedStallType.offer_our_pli" v-model="havePLI" label="I have Public Liability Insurance" />
              <div v-if="havePLI || !selectedStallType.offer_our_pli">
                <cgn-alert-info>
                  Please upload your Public Liability Insurance Certificate of Currency
                </cgn-alert-info>
                <cgn-form-input-file v-model="entryForm.stall_public_liability_insurance_file" />
                <cgn-form-input v-model="entryForm.stall_public_liability_insurance_expiry" type="date" label="Expiry date of Insurance" required />
              </div>
              <div v-if="havePLI === 0 && selectedStallType.offer_our_pli">
                <cgn-form-checkbox v-model="entryForm.stall_public_liability_insurance_use_ours" label="I apply to use Ipswich Show's Public Liability Insurance for this event" required />
              </div>
            </div>
          </div>
          <div v-if="selectedStallType.is_food_vendor" class="my-6">
            <div class="text-xl">
              Food Licence
            </div>
            <div v-if="eventDetails.stall_food_licence">
              <cgn-alert-success v-if="eventDetails.stall_food_licence.expiry.isAfter(eventDetails.start_date)">
                We already have your Food Licence document
              </cgn-alert-success>
              <div v-else>
                <cgn-alert-warning>
                  We have your Food Licence document but it will expire before the event.
                </cgn-alert-warning>
                <cgn-form-tristate v-model="haveFoodLicence" label="I have a renewed Food Licence" />
                <div v-if="haveFoodLicence">
                  <cgn-form-input-file v-model="entryForm.stall_food_licence_file" label="Upload new document now:" />
                  <cgn-form-input v-model="entryForm.stall_food_licence_expiry" type="date" label="Expiry date of Insurance" required />
                </div>
                <div v-if="haveFoodLicence === 0">
                  <cgn-form-checkbox v-model="entryForm.stall_food_licence_will_update_on_renewal" label="I will upload the Food Licence once it is renewed, but before the event" />
                </div>
              </div>
            </div>
            <div v-else>
              <cgn-alert-info>
                Please upload your Food Licence
              </cgn-alert-info>
              <cgn-form-input-file v-model="entryForm.stall_food_licence_file" />
              <cgn-form-input v-model="entryForm.stall_food_licence_expiry" type="date" label="Expiry date of Insurance" required />
            </div>
          </div>
        </template>
      </div>
      <template v-if="entryForm.stall_site_type_id">
        <div class="my-4 h-[300px] overflow-scroll">
          <div class="prose max-w-none text-sm dark:prose-invert" v-html="eventDetails.type.stallholder_application_terms_and_conditions?.content" />
        </div>
        <cgn-form-checkbox label="I have read and accept the Terms & Conditions" class="font-bold" required />

        <div class="py-2 font-bold">
          <div v-if="selectedStallType" class="my-2 rounded-md border p-2 text-sm">
            <div class="text-xl">
              {{ selectedStallType.name }}
            </div>
            {{ selectedStallType.site_description }}
            <div v-if="selectedStallLocation">
              <div class="text-lg">
                Location: {{ selectedStallLocation.name }}
              </div>
            </div>
            <div>
              Site Dimensions: {{ entryForm.stall_width }}m x {{ entryForm.stall_length }}m x {{ entryForm.stall_height }}m
            </div>
            <cgn-alert-warning v-if="!stall_area">
              Check your site dimensions
            </cgn-alert-warning>
            <div v-else>
              Area: {{ stall_area.toFixed(2) }}m<sup>2</sup>
            </div>

            <div class="text-lg">
              Power Requirements:
            </div>
            <cgn-alert-info v-if="entryForm.stall_power.length == 0">
              No Power Required
            </cgn-alert-info>
            <div v-for="power, idx in entryForm.stall_power" :key="idx">
              1 x
              {{ eventDetails.stall_power_types.find(e => e.id == power.id)?.name }}:
              {{ power.reason }}
            </div>
          </div>
        </div>

        <cgn-alert-danger v-if="errorMessage" class="font-bold">
          {{ errorMessage }}
        </cgn-alert-danger>

        <cgn-button color-brand fullwidth class="font-bold">
          <template v-if="paymentAmount > 0">
            Pay ${{ paymentAmount.toFixed(2) }} Now
          </template>
          <template v-else>
            Apply Now
          </template>
        </cgn-button>
      </template>
    </form>
  </div>
</template>

<script lang="ts">
import { CognitoPayment } from '~cognito/models/Cognito/Payment'
import { EventEntryForm, EventEntryFormStallPower } from '~cognito/models/Event/EntryForm'
import { EventEvent } from '~cognito/models/Event/Event'
import type { EventStallSiteType } from '~cognito/models/Event/StallSiteType'
import { $axios, requireLoggedIn } from '~cognito/plugins/axios'

class Templatevars {
  item?: string
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
  item: {
    type: String,
    required: true,
  },
})

const loading = ref(false)
const eventDetails = ref<EventEvent>(new EventEvent())
const entryForm = ref<EventEntryForm>(new EventEntryForm())
const entryFormPower = ref<number | string>('')
const entryFormPowerReason = ref('')

const profileFormValues = ref({
  email: '',
  mobile_phone: '',
  company_name: '',
  company_phone: '',
  abn: '',
  trading_name: '',
  website: '',
  facebook_link: '',
})
const havePLI = ref(null)
const haveFoodLicence = ref(null)

const errorMessage = ref('')
const has_payment = ref(false)
const payment_ok = ref(false)
const submitEntry = async () => {
  errorMessage.value = ''
  const result = await new EventEvent().submitEntry(entryForm.value)
  errorMessage.value = result.message
  if (result.success) {
    window.location = result.redirect
  }
}
const paymentAmount = computed(() => {
  return entryForm.value.totalPrice()
})

const selectedStallType = computed<EventStallSiteType>(() => {
  return eventDetails.value.stall_site_types.find(e => e.id == entryForm.value.stall_site_type_id)
})

const selectedStallLocation = computed(() => {
  return eventDetails.value.stall_locations.find(e => e.id == entryForm.value.stall_site_location_id)
})

const stall_area = computed(() => {
  return (entryForm.value.stall_width) * entryForm.value.stall_length
})

const addPower = () => {
  entryForm.value.stall_power.push(new EventEntryFormStallPower({ id: entryFormPower.value, reason: entryFormPowerReason.value }))
  entryFormPower.value = ''
  entryFormPowerReason.value = ''
}

const userStore = useUserStore()

const loadEvent = async () => {
  eventDetails.value = await entryForm.value.loadEvent(props.item)
  profileFormValues.value = userStore.user
}

const updateProfile = () => {
  $axios.post('/api/v1/cognito/user/update', profileFormValues.value)
    .then((res: any) => {
      loadEvent()
    })
}

onMounted(async () => {
  requireLoggedIn()
  loading.value = true
  new CognitoPayment().checkSuccessful()
    .then((data) => {
      has_payment.value = data.has_payment
      payment_ok.value = data.success
    })
  loadEvent()
  loading.value = false
})
</script>
