import type { MarketSiteType } from './MarketSiteType'
import { MarketType } from './MarketType'
import { CognitoBase } from '~cognito/models/Cognito/Base'
import { CognitoImage } from '~cognito/models/Cognito/Image'
import { CognitoTime } from '~cognito/models/Cognito/Time'
import { $axios } from '~cognito/plugins/axios'

class MarketEvent extends CognitoBase {
  name: string
  url: string
  date: CognitoTime
  type: MarketType
  image: CognitoImage
  blurb: string
  content: string
  terms: string
  terms_id: number
  applications_close_at: CognitoTime
  canApply: boolean
  site_types: MarketSiteType[]

  baseurl() {
    return '/api/v1/market/event'
  }

  constructor(source?: Partial<MarketEvent>) {
    super()
    this.name = ''
    this.url = ''
    this.type = new MarketType()
    this.image = new CognitoImage()
    this.blurb = ''
    this.content = ''
    this.terms = ''
    this.terms_id = 0
    this.canApply = false
    this.site_types = []
    Object.assign(this, source)
    this.date = new CognitoTime(source?.date)
    this.applications_close_at = new CognitoTime(source?.applications_close_at)
  }

  async upcomingEventList(type: string): Promise<MarketEvent[]> {
    const res = await $axios.get(`${this.baseurl()}/upcomingEventList?type_id=${type}`)
    return this.map(res.data.data)
  }
}

export { MarketEvent }
