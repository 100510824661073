import { MarketEvent } from './MarketEvent'
import { MarketSiteType } from './MarketSiteType'
import { CognitoBase } from '~cognito/models/Cognito/Base'
import { $axios } from '~cognito/plugins/axios'

class MarketApplicationForm {
  products_and_display_description: string
  company_product_overview: string
  stall_size: string
  require_power: boolean
  reason_for_needing_power: string
  site_type: number
  signature: string

  constructor(source?: Partial<MarketApplicationForm>) {
    this.products_and_display_description = ''
    this.company_product_overview = ''
    this.stall_size = ''
    this.require_power = false
    this.reason_for_needing_power = ''
    this.site_type = 0
    this.signature = ''
    Object.assign(this, source)
  }
}

class MarketApplication extends CognitoBase {
  event: MarketEvent
  siteType: MarketSiteType
  paid_at: string
  products_and_display_description: string
  company_product_overview: string
  stall_size: string
  require_power: boolean
  reason_for_needing_power: string

  baseurl() {
    return '/api/v1/market/application'
  }

  constructor(source?: Partial<MarketApplication>) {
    super()
    this.event = new MarketEvent()
    this.siteType = new MarketSiteType()
    this.paid_at = ''
    this.products_and_display_description = ''
    this.company_product_overview = ''
    this.stall_size = ''
    this.require_power = false
    this.reason_for_needing_power = ''
    Object.assign(this, source)
  }

  async upcomingList(): Promise<MarketApplication[]> {
    const res = await $axios.get(`${this.baseurl()}/upcomingList`)
    return res.data
  }

  async findLastApplication(): Promise<MarketApplication> {
    const res = await $axios.get(`${this.baseurl()}/findLastApplication`)
    return res.data
  }

  async submitApplication(data: MarketApplicationForm): Promise<{
    success: boolean
    error: string
    payment: string
  }> {
    data.url = btoa(window.location)
    return (await $axios.post(`${this.baseurl()}/submitApplication`, data)).data
  }

  async getInvoice(payment: number): Promise<{
    invoiceText: string
  }> {
    return (await $axios.post('/api/v1/market/application/invoiceText', {
      payment,
    })).data
  }
}

export { MarketApplication, MarketApplicationForm }
