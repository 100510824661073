<template>
  <cgn-router-view />
</template>

<script setup lang="ts">
useSchemaOrg([
  // @todo Select Identity: https://unhead-schema-org.harlanzw.com//guide/guides/identity
  defineOrganization({
    name: 'Ipswich Show Society',
    logo: () => usePagesStore().currentDomain.favicon.url,
  }),
  defineWebSite({
    name: 'Ipswich Show',
  }),
  defineWebPage(),
])
</script>

<!-- MailerLite Universal -->

<script lang="ts">
if (typeof window != 'undefined') {
  setTimeout(() => {
    (function (m, a, i, l, e, r) {
      m.MailerLiteObject = e

      function f() {
        // eslint-disable-next-line prefer-rest-params
        const c = { a: arguments, q: [] }
        const r = this.push(c)
        return typeof r != 'number' ? r : f.bind(c.q)
      }

      f.q = f.q || []
      m[e] = m[e] || f.bind(f.q)
      m[e].q = m[e].q || f.q
      r = a.createElement(i)
      const _ = a.getElementsByTagName(i)[0]
      r.async = 1
      r.src = `${l}?v${~~(new Date().getTime() / 1000000)}`
      _.parentNode.insertBefore(r, _)
    })(window, document, 'script', 'https://static.mailerlite.com/js/universal.js', 'ml')
    const ml_account = ml('accounts', '3449042', 'y2x5v5r2a1', 'load')
  }, 15000)
}
</script>

<!-- End MailerLite Universal -->
