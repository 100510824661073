<template>
  <cgn-caravan-book>
    <div class="text-2xl">
      {{ props.templatevar.heading }}
    </div>
    <div class="prose dark:prose-dark max-w-full mt-2" v-html="props.templatevar.content" />
    <cgn-lazy-image :image-id="props.templatevar.site_plan" image-aspect="16x9w" image-width="300" class="w-full mt-2" />
  </cgn-caravan-book>
</template>

<script lang="ts">
class Templatevars {
  heading?: string
  content?: string
  site_plan?: string
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
})
</script>
