<template>
  <div v-if="props.item">
    <div v-if="event.url">
      <div class="text-2xl">
        {{ event.name }}
        {{ event.date.toHumanDateString(true) }}
      </div>
      <div v-if="has_payment">
        <div
          v-if="payment_ok"
        >
          <cgn-alert-success
            class="max-w-2xl w-full mx-auto my-2"
          >
            Payment Successful
          </cgn-alert-success>
          <div class="max-w-3xl w-full mx-auto bg-white text-black shadow" v-html="invoiceText" />
        </div>
        <cgn-alert-danger
          v-else
          class="max-w-2xl w-full mx-auto my-2"
        >
          Payment Failed
        </cgn-alert-danger>
      </div>
      <div v-else-if="event.canApply">
        <div class="text-sm">
          Applications close {{ event.applications_close_at.toHumanTimeString() }} {{ event.applications_close_at.toHumanDateString(false) }}
        </div>
        <div>
          <cgn-lazy-image :image="event.image" width="300" />
        </div>
        <div class="prose dark:prose-dark" v-html="event.content" />

        <div class="text-2xl">
          Available Sites
        </div>
        <div v-if="event.site_types.length">
          <div v-for="site_type in event.site_types" :key="site_type.id">
            <div>
              {{ site_type.name }}
              ${{ site_type.amount.toFixed(2) }}
              Qty remaining: {{ site_type.qty_available }}
              <cgn-button color-info @click="selected_site_type = site_type">
                Apply
              </cgn-button>
            </div>
          </div>
        </div>
        <cgn-alert-warning v-else>
          Sorry, no sites are available
        </cgn-alert-warning>

        <div v-if="selected_site_type.id">
          <div class="text-2xl">
            {{ selected_site_type.name }}
          </div>
          <div class="sm:grid grid-rows-4 grid-flow-col p-2">
            <div>
              Business Name:
              <strong>
                {{ profile.company_name }}
              </strong>
            </div>
            <div>
              Trading Name:
              <strong>
                {{ profile.trading_name }}
              </strong>
            </div>
            <div>
              Contact Name:
              <strong>
                {{ profile.first_name }}
                {{ profile.last_name }}
              </strong>
            </div>
            <div>
              Email:
              <strong>
                {{ profile.email }}
              </strong>
            </div>

            <div>
              Phone - Business:
              <strong>
                {{ profile.company_phone }}
              </strong>
            </div>
            <div>
              Phone - Mobile:
              <strong>
                {{ profile.mobile_phone }}
              </strong>
            </div>
            <div>
              Website:
              <strong>
                {{ profile.website }}
              </strong>
            </div>
            <div>
              Facebook:
              <strong>
                {{ profile.facebook_link }}
              </strong>
            </div>
          </div>
          <div class="p-2">
            Address:
            <strong>
              {{ profile.address }}
            </strong>
          </div>
          <cgn-alert-warning>
            If any of the above details are not current please update them in
            <router-link to="/profile" class="text-blue-500">
              My Profile
            </router-link>
          </cgn-alert-warning>

          <form class="p-2" @submit.prevent="submitApplication">
            <cgn-form-input-textarea v-model="application.products_and_display_description" label="Describe the products and display that you intend to have on your site" required />
            <cgn-form-input-textarea v-model="application.company_product_overview" label="Company/Product Overview for marketing material: (4-5 sentences)" required />
            <cgn-form-input-text v-model="application.stall_size" label="Site Dimensions (Width X Length X Height)" required />
            <cgn-form-dropdown v-model="application.require_power" label="Do you require power?" :options="[{ id: 0, name: 'No' }, { id: 1, name: 'Yes' }]" required />
            <cgn-form-input-textarea v-if="application.require_power" v-model="application.reason_for_needing_power" label="Please state your requirements? (e.g Computer, 1 x 10 Amp). Powered sites are limited and preference will be given to food vendors" required />
            <div class="prose-sm dark:prose-invert" v-html="event.terms" />
            <cgn-alert-success>
              I have read, understood and agree to the Terms and Conditions.
            </cgn-alert-success>
            <VueSignaturePad
              ref="signaturePad" width="100%" height="200px"
              class="border-4 border-solid border-gray-400 rounded-md bg-gray-100 my-2"
            />
            <cgn-alert-danger v-if="submit_error">
              {{ submit_error }}
            </cgn-alert-danger>
            <cgn-button color-brand>
              Pay ${{ selected_site_type.amount.toFixed(2) }} Now
            </cgn-button>
          </form>
        </div>
      </div>
      <cgn-alert-warning v-else>
        Sorry, applications have closed
      </cgn-alert-warning>
    </div>
  </div>
  <div v-else>
    <div v-for="eventitem in events" :key="eventitem.url">
      <cgn-button v-if="eventitem.canApply" :url="`/stallholder-application/${eventitem.url}`" color-brand>
        Apply now
      </cgn-button>
      {{ eventitem.name }}
      {{ eventitem.date }}
      {{ eventitem.applications_close_at }}
    </div>
  </div>
</template>

<script lang="ts">
import { MarketApplication, MarketApplicationForm } from '~/models/MarketApplication'
import { MarketEvent } from '~/models/MarketEvent'
import { MarketSiteType } from '~/models/MarketSiteType'
import { CognitoPayment } from '~cognito/models/Cognito/Payment'
import { TplUser } from '~/models/TplUser'

class Templatevars {
  item?: string
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
  item: {
    type: String,
  },
})

const profile = ref<TplUser>(new TplUser())
const event = ref<MarketEvent>(new MarketEvent())
const events = ref<MarketEvent[]>([])
const selected_site_type = ref<MarketSiteType>(new MarketSiteType())
const application = ref<MarketApplicationForm>(new MarketApplicationForm())

const lastApplication = ref<MarketApplication>(new MarketApplication())

const signaturePad = ref()
const form_disabled = ref(false)
const submit_error = ref('')

const has_payment = ref(false)
const payment_ok = ref(false)
const invoiceText = ref('')

const loadEvent = async () => {
  if (props.item) {
    event.value = await new MarketEvent().find_one_mapped({ url: props.item })
  } else {
    event.value = new MarketEvent()
    events.value = await new MarketEvent().upcomingEventList('')
  }
  selected_site_type.value = new MarketSiteType()

  // Load stallholder's last application
  lastApplication.value = await new MarketApplication().findLastApplication()
  if (lastApplication.value) {
    application.value.products_and_display_description = lastApplication.value.products_and_display_description
    application.value.company_product_overview = lastApplication.value.company_product_overview
    application.value.stall_size = lastApplication.value.stall_size
    application.value.require_power = lastApplication.value.require_power ? 1 : 0
    application.value.reason_for_needing_power = lastApplication.value.reason_for_needing_power
  }
}

const submitApplication = () => {
  const { isEmpty, data } = signaturePad.value.saveSignature()

  application.value.signature = data
  application.value.site_type = selected_site_type.value.id
  application.value.terms_signed = event.value.terms_id
  console.log(application.value)

  submit_error.value = ''
  if (isEmpty) {
    submit_error.value = 'You need to sign'
    form_disabled.value = false
    return
  }

  // submit to pay
  new MarketApplication().submitApplication(application.value)
    .then((res) => {
      if (res.success) {
        window.location = res.payment
      } else {
        submit_error.value = res.error
      }
    })
}

watch(() => props.item, () => {
  loadEvent()
})
onMounted(() => {
  useUserStore().checkLoggedIn()
  loadEvent()

  new CognitoPayment().checkSuccessful()
    .then((data) => {
      has_payment.value = data.has_payment
      payment_ok.value = data.success
      if (data.success) {
        new MarketApplication().getInvoice(data.payment.id)
          .then((data) => {
            invoiceText.value = data.invoiceText
          })
      }
    })

  new TplUser().getProfile()
    .then((data) => {
      profile.value = data
    })
})
// TODO check all documents exist and are valid
</script>
