<template>
  <div class="relative">
    <h1 class="absolute bottom-5 left-5 font-display text-3xl font-semibold uppercase text-white drop-shadow-lg sm:text-5xl md:text-7xl">
      {{ templatevar.title }}
    </h1>
    <cgn-lazy-image :image="templatevar.background" class="w-full" />
  </div>
</template>

<script lang="ts">
import type { CognitoImage } from '~cognito/models/Cognito/Image'

class Templatevars {
  title?: string
  background?: CognitoImage
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
})
</script>
