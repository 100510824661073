<template>
  <div>
    <div v-for="event in events" :key="event.id" class="mb-10">
      <cgn-lazy-image v-if="event.image" :image="event.image" class="mb-2 w-full" />
      <h2 class="mb-2 text-2xl">
        {{ event.name }}
      </h2>
      <div class="mb-2 font-bold">
        {{ event.start_date.betweenDateString(event.end_date) }}
      </div>
      <div class="mb-2">
        <div v-for="date in event.dates" :key="date.start_time">
          <span v-if="!event.start_date.isSameDay(event.end_date)">
            {{ date.start.format('eee do MMMM') }}
            &mdash;
          </span>
          {{ date.start.toHumanTimeString() }}
          -
          {{ date.end.toHumanTimeString() }}
        </div>
      </div>
      <div class="prose max-w-none dark:prose-invert" v-html="event.blurb" />
      <cgn-button v-if="event.can_buy_spectator_ticket" color-brand :url="`/buy-spectator/${event.url}`" class="mr-2">
        Get Tickets
      </cgn-button>
      <cgn-button v-if="useUserStore().user?.is_admin && event.can_enter" color-brand :url="`/enter/${event.url}`" class="mr-2">
        Enter Now
      </cgn-button>
      <cgn-button v-if="useUserStore().user?.id && event.can_apply_stall_site" color-brand :url="`/apply-stall/${event.url}`" class="mr-2">
        Apply for a stall site
      </cgn-button>
    </div>
  </div>
</template>

<script lang="ts">
import { EventEvent } from '~cognito/models/Event/Event'

class Templatevars {
  type?: string
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
})
const events = ref<EventEvent[]>([])

const loadEvents = async () => {
  events.value = await new EventEvent().upcomingEventList(props.templatevar.type)
}

onMounted(() => {
  loadEvents()
})
onServerPrefetch(async () => {
  await loadEvents()
})
</script>
