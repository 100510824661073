<template>
  <div>
    <div v-for="event in events" :key="event.url" class="sm:grid sm:grid-cols-4 m-4 bg-white dark:bg-gray-800 shadow-md">
      <div class="bg-gray-200 dark:bg-gray-900">
        <cgn-lazy-image :image="event.image" width="300" class="w-full" />
      </div>
      <div class="p-4 text-center sm:text-left col-span-3">
        <h2 class="text-2xl font-display">
          {{ event.name }}
        </h2>
        <h3 class="text-lg font-display pb-2">
          {{ format(parseISO(event.date), 'EEE, do MMM yyyy') }}
        </h3>
        <div class="prose dark:prose-invert prose-p:my-2" v-html="event.content" />
        <div class="flex justify-between">
          <div>
            <cgn-button v-if="event.canApply && false" color-brand class="w-full sm:w-auto inline-block" extra-classes="w-full sm:w-auto" :url="`/applyEvent/${event.url}`">
              Apply Now
            </cgn-button>
          </div>
          <div>
            <cgn-button v-if="!templatevar.type && event.type.page" color-primary class="w-full sm:w-auto inline-block" extra-classes="w-full sm:w-auto" :url="`/${event.type.page}`">
              Show all {{ event.type.name }}
            </cgn-button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="events.length == 0">
      No upcoming events found
    </div>
  </div>
</template>

<script lang="ts">
import { format, parseISO } from 'date-fns'
import { MarketEvent } from '~/models/MarketEvent'
class Templatevars {
  type?: string
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
})
const events = ref<MarketEvent[]>([])

const loadEvents = async () => {
  events.value = await new MarketEvent().upcomingEventList(props.templatevar.type)
}

onMounted(() => {
  loadEvents()
})
onServerPrefetch(async () => {
  await loadEvents()
})
// TODO remove apply now comment
</script>
