<template>
  <div>
    <h2 v-if="templatevar.heading" class="text-4xl font-black text-center mb-5 mt-5">
      {{ templatevar.heading }}
    </h2>
    <div class="flex justify-center flex-wrap">
      <div v-for="link in links.data" :key="link.id" class="lg:w-1/3 sm:w-1/2 p-2">
        <router-link v-if="link.file" :to="`/pdf/${link.file}`">
          <cgn-lazy-image :image="link.image" />
        </router-link>
        <component
          :is="link.link.startsWith('http') ? 'a' : 'router-link'"
          v-else-if="link.link"
          :to="link.link"
          :href="link.link"
          :target="link.link.startsWith('http') ? '_blank' : ''"
        >
          <cgn-lazy-image :image="link.image" />
        </component>
        <div v-else>
          <cgn-lazy-image :image="link.image" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { CognitoLink } from '~cognito/models/Cognito/Link'
class Templatevars {
  heading?: string
  parent_link?: string
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
})

const links = ref<CognitoLink[]>([])

const loadLinks = async () => {
  links.value = await new CognitoLink().find_many({
    image_aspect: 'Square',
    image_width: 300,
    parent: props.templatevar.parent_link,
  })
}

onMounted(() => {
  loadLinks()
})
onServerPrefetch(async () => {
  await loadLinks()
})
</script>
